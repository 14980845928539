<template>
  <div id="homePage">
    <div class="page-all">
      <img src="../assets/img/newtop.png" class="top-img" />
      <div class="page-inner">
        <div class="inner-left">
          <div class="left-top">
            <img src="../assets/img/date.png" />
            <div class="text-blue">{{ today }}</div>
            {{ weekday }}
            <div class="text-blue">{{ time }}</div>
          </div>
          <div class="page-box left-mid">
            <div class="box-title">
              <img src="../assets/img/line1.png" />
              <div class="title-text">产量趋势图</div>
            </div>
            <div class="left-mid-btn">
              <div
                :class="[
                  isFirstHalf
                    ? 'left-mid-btn-box left-mid-btn-active'
                    : 'left-mid-btn-box',
                ]"
                @click="clickHalf(1)"
              >
                上半年
              </div>
              <div
                :class="[
                  isFirstHalf
                    ? 'left-mid-btn-box'
                    : 'left-mid-btn-box left-mid-btn-active',
                ]"
                @click="clickHalf(2)"
              >
                下半年
              </div>
            </div>
            <div id="echarts1"></div>
            <div id="echarts2"></div>
          </div>
          <div class="page-box left-bot">
            <div class="box-title">
              <img src="../assets/img/line1.png" />
              <div class="title-text">机台监控</div>
            </div>
            <div class="left-bot-table">
              <div class="left-bot-table-head">
                <div class="left-bot-head-box">设备类型</div>
                <div class="left-bot-head-box">设备名称</div>
                <div class="left-bot-head-box">设备状态</div>
              </div>
              <swiper
                v-if="swiperOne.length"
                class="jtjk"
                :options="swiperOption1"
              >
                <swiper-slide
                  class="left-bot-table-line"
                  v-for="(item, i) in swiperOne"
                  :key="i"
                >
                  <div class="left-bot-table-box">{{ item.deviceName }}</div>
                  <div class="left-bot-table-box">{{ item.deviceSn }}</div>
                  <div class="left-bot-table-box">{{ item.status }}</div>
                </swiper-slide>
              </swiper>
            </div>
          </div>
        </div>
        <div class="inner-mid">
          <div class="mid-top">
            <div class="mid-top-box">
              <img src="../assets/img/icon1.png" />
              <div class="mid-top-box-info">
                总产量
                <div class="mid-num text-lightblue">
                  {{ productAmount }}
                </div>
              </div>
            </div>
            <div class="mid-top-box">
              <img src="../assets/img/icon2.png" />
              <div class="mid-top-box-info">
                监控数量
                <div class="mid-num text-yellow">{{ monitorAmount }}</div>
              </div>
            </div>
            <div class="mid-top-box">
              <img src="../assets/img/icon3.png" />
              <div class="mid-top-box-info">
                网关总数
                <div class="mid-num text-blue">{{ gatewayAmount }}</div>
              </div>
            </div>
            <div class="mid-top-box">
              <img src="../assets/img/icon4.png" />
              <div class="mid-top-box-info">
                在线数量
                <div class="mid-num text-green">{{ onlineAmount }}</div>
              </div>
            </div>
          </div>
          <div class="mid-mid">
            <iframe src="https://roma.720yun.com/vr/eae58a17412efe9e/" frameborder="0"></iframe>
          </div>
          <div class="mid-bot">
            <div class="page-box mid-bot-left">
              <div class="box-title">
                <img src="../assets/img/line2.png" />
                <div class="title-text">公司介绍</div>
              </div>
              <div class="gsjs">
                <div class="mid-bot-left-box">
                  <img :src="apiUrl + swiperTwo.image" class="mid-bot-img" />
                  <div class="mid-bot-left-text">{{ swiperTwo.detail }}</div>
                </div>
              </div>
              <!-- <swiper v-if="swiperTwo.length" class="gsjs" :options="swiperOption2">
                <swiper-slide v-for="(item, i) in swiperTwo" :key="i">
                  <div class="mid-bot-left-box">
                    <img :src="apiUrl + item.image" class="mid-bot-img" />
                    <div class="mid-bot-left-text">{{ item.detail }}</div>
                  </div>
                </swiper-slide>
              </swiper> -->
            </div>
            <div class="page-box mid-bot-right">
              <div class="box-title">
                <img src="../assets/img/line3.png" />
                <div class="title-text">厂间信息</div>
              </div>
              <swiper
                v-if="swiperThree.length"
                class="cjxx"
                :options="swiperOption3"
              >
                <swiper-slide
                  class="mid-bot-right-line"
                  v-for="(item, i) in swiperThree"
                  :key="i"
                >
                  <img src="../assets/img/building.png" />
                  <div class="mid-bot-right-name">{{ item.articleTitle }}</div>
                  <div class="mid-bot-right-desc">{{ item.detail }}</div>
                </swiper-slide>
              </swiper>
            </div>
          </div>
        </div>
        <div class="inner-right">
          <div class="right-top">
            <!-- <img src="../assets/img/user.png" /> -->
            <div class="text-blue">后台管理</div>
            <!-- <div class="right-btn">退出</div> -->
          </div>
          <div class="page-box right-bot">
            <div class="box-title">
              <img src="../assets/img/line3.png" />
              <div class="title-text">能耗数据</div>
            </div>
            <div class="echarts3-box">
              <div id="echarts3"></div>
              <div id="echarts4"></div>
            </div>
            <div class="box-title">
              <img src="../assets/img/line3.png" />
              <div class="title-text">告警信息</div>
            </div>
            <div class="right-bot-list-part">
              <swiper
                v-if="noticeList.length"
                class="right-bot-list"
                :options="swiperOption4"
              >
                <swiper-slide
                  class="right-bot-list-box"
                  v-for="(item, i) in noticeList"
                  :key="i"
                >
                  <div class="right-bot-text">{{ item.remark }}</div>
                  <div class="right-bot-date">{{ item.date }}</div>
                </swiper-slide>
              </swiper>
            </div>
            <div class="box-title">
              <img src="../assets/img/line3.png" />
              <div class="title-text">产能进度</div>
            </div>
            <div class="echarts5-box"><div id="echarts5"></div></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";

import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

import {
  getProductAmountMonth,
  getArticleInfo,
  getWorkShopInfo,
  getElectricGasAmountMonth,
  getWarnInfo,
  getDeviceStatus,
  getSpeedList,
  getOnlineCount,
} from "@/request/Api";

export default {
  name: "homePage",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      //日期
      today: "",
      weekday: "",
      time: "",
      timer: "",
      isFirstHalf: true,
      //产量趋势图
      echartsOptionsOne: {},
      echartsNameOne: [],
      echartsNumOne: [],
      echartsOptionsTwo: {},
      echartsNameTwo: [],
      echartsNumTwo: [],
      //机台监控
      swiperOne: [],
      swiperOption1: {
        direction: "vertical",
        loop: true,
        slidesPerView: 7,
        autoplay: {
          delay: 0,
          disableOnInteraction: false,
        },
        speed: 2000,
      },
      //顶部在线总数统计
      productAmount: "",
      monitorAmount: "",
      gatewayAmount: "",
      onlineAmount: "",
      //公司介绍
      swiperTwo: [],
      swiperOption2: {
        loop: true,
        slidesPerView: 1,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
      },
      //厂间信息
      swiperThree: [],
      swiperOption3: {
        direction: "vertical",
        loop: true,
        slidesPerView: 4,
        autoplay: {
          delay: 0,
          disableOnInteraction: false,
        },
        speed: 2000,
      },
      //能耗数据
      echartsOptionsThree: {},
      echartsNameThree: [],
      echartsNumThree: [],
      echartsNumFour: [],
      echartsOptionsFour: {},
      echartsNameFour: [],
      echartsNumFive: [],
      echartsNumSix: [],
      //告警信息
      tempNoticeList: [],
      noticeList: [],
      swiperOption4: {
        direction: "vertical",
        loop: true,
        slidesPerView: 5,
        // autoplay: {
        //   delay: 0,
        //   disableOnInteraction: false,
        // },
        speed: 2000,
      },
      //产能进度
      echartsOptions: {},
      echartsName: [],
      echartsData: [],
    };
  },
  async created() {
    this.initWebSocket(); //连接websocket
    window.addEventListener("onmessageWS", this.getSocketData); //监听心跳
    window.onbeforeunload = function () {
      //窗口关闭时断开连接
      this.$websocket.close();
    };
  },
  mounted() {
    //获取当前时间
    this.timer = setInterval(() => {
      var date = new Date();
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      if (m <= 9) {
        m = "0" + m;
      }
      var d = date.getDate();
      if (d <= 9) {
        d = "0" + d;
      }
      var weekDays = [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ];
      this.today = y + "-" + m + "-" + d;
      this.weekday = weekDays[date.getDay()];
      var h = date.getHours();
      if (h <= 9) {
        h = "0" + h;
      }
      var ms = date.getMinutes();
      if (ms <= 9) {
        ms = "0" + ms;
      }
      var s = date.getSeconds();
      if (s <= 9) {
        s = "0" + s;
      }
      this.time = h + ":" + ms + ":" + s;
    }, 1000);
    //获取产量趋势
    this.getEchartsOne();
    //获取机台监控
    this.getSwiperOne();
    //获取顶部在线总数统计
    this.getTopNum();
    //获取公司介绍
    this.getSwiperTwo();
    //获取厂间信息
    this.getSwiperThree();
    //获取能耗数据
    this.getEchartsTwo();
    //获取告警信息
    this.getNoticeList();
    //获取产能进度
    this.getEchartsThree();
  },
  beforeDestroy() {
    //清除定时器
    clearInterval(this.timer);
  },
  methods: {
    //连接websocket
    async initWebSocket() {
      //连接websocket
      this.$websocket.initWebSocket();
    },
    //获取websocket推送数据
    getSocketData(res) {
      //获取心跳数据
      let data = JSON.parse(res.detail.data); //心跳传输的数据
      if (data.code == 2) {
        // this.$websocket.sendWebsocket();
        return;
      }
      if (data.code == 3) {
        //告警信息
        let list = data.data;
        for (let i = 0; i < list.length; i++) {
          this.tempNoticeList.push({
            remark: list[i].remark,
            date: data.dateTime,
          });
        }
        if (this.tempNoticeList.length > 20) {
          let num = this.tempNoticeList.length - 20;
          this.noticeList = this.tempNoticeList.splice(0, num);
        }
        return;
      }
      if (data.code == 4) {
        //在线总数统计
        this.productAmount = data.data.productAmount;
        this.monitorAmount = data.data.monitorAmount;
        this.gatewayAmount = data.data.gatewayAmount;
        this.onlineAmount = data.data.onlineAmount;
        return;
      }
      if (data.code == 5) {
        //机台监控
        this.swiperOne = data.data;
        return;
      }
      if (data.code == 6) {
        //更新产能进度
        this.echartsName = [];
        this.echartsData = [];
        for (let i = 0; i < data.data.length; i++) {
          this.echartsName.push(data.data[i].device);
          this.echartsData.push(data.data[i].speed);
        }
        this.echartsOptions.yAxis.data = this.echartsName;
        this.echartsOptions.series[0].data = this.echartsData;
        echarts
          .init(document.getElementById("echarts5"))
          .setOption(this.echartsOptions);
        return;
      }
      if (data.code == 7) {
        //更新产量趋势图
        this.echartsNameOne = [];
        this.echartsNumOne = [];
        this.echartsNameTwo = [];
        this.echartsNumTwo = [];
        for (let i = 0; i < data.data.length; i++) {
          if (i < 6) {
            this.echartsNameOne.push(data.data[i].title);
            this.echartsNumOne.push(data.data[i].value);
          } else {
            this.echartsNameTwo.push(data.data[i].title);
            this.echartsNumTwo.push(data.data[i].value);
          }
        }
        this.echartsOptionsOne.xAxis.data = this.echartsNameOne;
        this.echartsOptionsOne.series[0].data = this.echartsNumOne;
        echarts
          .init(document.getElementById("echarts1"))
          .setOption(this.echartsOptionsOne);
        this.echartsOptionsTwo.xAxis.data = this.echartsNameTwo;
        this.echartsOptionsTwo.series[0].data = this.echartsNumTwo;
        echarts
          .init(document.getElementById("echarts2"))
          .setOption(this.echartsOptionsTwo);
        return;
      }
      if (data.code == 8) {
        //更新能耗数据
        this.echartsNameThree = [];
        this.echartsNumThree = [];
        this.echartsNumFour = [];
        this.echartsNameFour = [];
        this.echartsNumFive = [];
        this.echartsNumSix = [];
        for (let i = 0; i < data.data.length; i++) {
          if (i < 6) {
            this.echartsNameThree.push(data.data[i].month);
            this.echartsNumThree.push(data.data[i].gas);
            this.echartsNumFour.push(data.data[i].electric);
          } else {
            this.echartsNameFour.push(data.data[i].month);
            this.echartsNumFive.push(data.data[i].gas);
            this.echartsNumSix.push(data.data[i].electric);
          }
        }
        this.echartsOptionsThree.xAxis.data = this.echartsNameThree;
        this.echartsOptionsThree.series[0].data = this.echartsNumThree;
        this.echartsOptionsThree.series[1].data = this.echartsNumFour;
        echarts
          .init(document.getElementById("echarts3"))
          .setOption(this.echartsOptionsThree);
        this.echartsOptionsFour.xAxis.data = this.echartsNameFour;
        this.echartsOptionsFour.series[0].data = this.echartsNumFive;
        this.echartsOptionsFour.series[1].data = this.echartsNumSix;
        echarts
          .init(document.getElementById("echarts4"))
          .setOption(this.echartsOptionsFour);
        return;
      }
    },
    //切换上下半年
    clickHalf(i) {
      if (i == 1) {
        this.isFirstHalf = true;
        document.getElementById("echarts1").style.display = "block";
        document.getElementById("echarts3").style.display = "block";
      } else {
        this.isFirstHalf = false;
        document.getElementById("echarts1").style.display = "none";
        document.getElementById("echarts3").style.display = "none";
      }
    },
    //获取产量趋势
    getEchartsOne() {
      getProductAmountMonth().then((res) => {
        let data = res.data;
        for (let i = 0; i < data.length; i++) {
          if (i < 6) {
            this.echartsNameOne.push(data[i].title);
            this.echartsNumOne.push(data[i].value);
          } else {
            this.echartsNameTwo.push(data[i].title);
            this.echartsNumTwo.push(data[i].value);
          }
        }
        var echarts1 = echarts.init(document.getElementById("echarts1"));
        this.echartsOptionsOne = {
          title: {
            text: "产量统计",
            textStyle: {
              color: "#fff",
              fontSize: "14",
              fontWeight: "400",
            },
          },
          grid: { right: "0%", left: "15%" },
          xAxis: {
            data: this.echartsNameOne,
            axisLine: {
              lineStyle: { color: "#103665" },
            },
            axisTick: { length: 0 },
            axisLabel: { color: "#71C8FB", fontSize: "14" },
          },
          yAxis: {
            splitLine: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: { color: "#103665" },
            },
            axisLabel: { color: "#fff", fontSize: "14" },
          },
          series: [
            {
              data: this.echartsNumOne,
              type: "line",
              smooth: true,
              lineStyle: {
                normal: {
                  width: 2,
                  color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                    {
                      offset: 0,
                      color: "#1548F7",
                    },
                    {
                      offset: 1,
                      color: "#08B2FA",
                    },
                  ]),
                  shadowColor: "#05106C",
                  shadowOffsetY: 10,
                  shadowBlur: 9,
                },
              },
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "rgba(6,139,248,0.5)",
                  },
                  {
                    offset: 1,
                    color: "rgba(4,112,248,0)",
                  },
                ]),
              },
            },
          ],
          tooltip: { show: true },
        };
        echarts1.setOption(this.echartsOptionsOne);
        var echarts2 = echarts.init(document.getElementById("echarts2"));
        this.echartsOptionsTwo = {
          title: {
            text: "产量统计",
            textStyle: {
              color: "#fff",
              fontSize: "14",
              fontWeight: "400",
            },
          },
          grid: { right: "0%", left: "15%" },
          xAxis: {
            data: this.echartsNameTwo,
            axisLine: {
              lineStyle: { color: "#103665" },
            },
            axisTick: { length: 0 },
            axisLabel: { color: "#71C8FB", fontSize: "14" },
          },
          yAxis: {
            splitLine: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: { color: "#103665" },
            },
            axisLabel: { color: "#fff", fontSize: "14" },
          },
          series: [
            {
              data: this.echartsNumTwo,
              type: "line",
              smooth: true,
              lineStyle: {
                normal: {
                  width: 2,
                  color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                    {
                      offset: 0,
                      color: "#1548F7",
                    },
                    {
                      offset: 1,
                      color: "#08B2FA",
                    },
                  ]),
                  shadowColor: "#05106C",
                  shadowOffsetY: 10,
                  shadowBlur: 9,
                },
              },
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "rgba(6,139,248,0.5)",
                  },
                  {
                    offset: 1,
                    color: "rgba(4,112,248,0)",
                  },
                ]),
              },
            },
          ],
          tooltip: { show: true },
        };
        echarts2.setOption(this.echartsOptionsTwo);
      });
    },
    //获取机台监控
    getSwiperOne() {
      getDeviceStatus().then((res) => {
        this.swiperOne = res.data;
      });
    },
    //获取顶部在线总数统计
    getTopNum() {
      getOnlineCount().then((res) => {
        this.productAmount = res.data.productAmount;
        this.monitorAmount = res.data.monitorAmount;
        this.gatewayAmount = res.data.gatewayAmount;
        this.onlineAmount = res.data.onlineAmount;
      });
    },
    //获取公司介绍
    getSwiperTwo() {
      getArticleInfo().then((res) => {
        this.swiperTwo = res.data;
      });
    },
    //获取厂间信息
    getSwiperThree() {
      getWorkShopInfo().then((res) => {
        this.swiperThree = res.data;
      });
    },
    //获取能耗数据
    getEchartsTwo() {
      getElectricGasAmountMonth().then((res) => {
        let data = res.data;
        for (let i = 0; i < data.length; i++) {
          if (i < 6) {
            this.echartsNameThree.push(data[i].month);
            this.echartsNumThree.push(data[i].gas);
            this.echartsNumFour.push(data[i].electric);
          } else {
            this.echartsNameFour.push(data[i].month);
            this.echartsNumFive.push(data[i].gas);
            this.echartsNumSix.push(data[i].electric);
          }
        }
        var echarts3 = echarts.init(document.getElementById("echarts3"));
        this.echartsOptionsThree = {
          xAxis: {
            data: this.echartsNameThree,
            axisLine: {
              lineStyle: { color: "#3E5063" },
            },
            axisTick: { length: 0 },
            axisLabel: { color: "#71C8FB", fontSize: "14" },
          },
          yAxis: {
            splitLine: {
              lineStyle: {
                color: "#3E5063",
              },
            },
            axisLine: {
              show: true,
              lineStyle: { color: "#3E5063" },
            },
            axisLabel: { color: "#ECF0FF", fontSize: "14" },
          },
          legend: {
            show: true,
            right: 0,
            textStyle: {
              color: "#ECF0FF",
              fontSize: "12",
            },
          },
          grid: { bottom: "15%", right: "0%", left: "15%" },
          series: [
            {
              data: this.echartsNumThree,
              name: "气数值",
              type: "bar",
              stack: "x",
              barWidth: "20%",
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#27E6AB",
                  },
                  {
                    offset: 1,
                    color: "#0B2375",
                  },
                ]),
              },
            },
            {
              data: this.echartsNumFour,
              name: "电数值",
              type: "bar",
              stack: "x",
              barWidth: "20%",
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#FDC81E",
                  },
                  {
                    offset: 1,
                    color: "#0B2375",
                  },
                ]),
              },
            },
          ],
          tooltip: { show: true },
        };
        echarts3.setOption(this.echartsOptionsThree);
        echarts3
          .off("legendselectchanged")
          .on("legendselectchanged", function (params) {
            if (params.name == "气数值") {
              if (!params.selected["气数值"]) {
                if (!params.selected["电数值"]) {
                  echarts3.setOption({
                    legend: { selected: { 电数值: true } },
                  });
                }
              }
            } else {
              if (!params.selected["电数值"]) {
                if (!params.selected["气数值"]) {
                  echarts3.setOption({
                    legend: { selected: { 气数值: true } },
                  });
                }
              }
            }
          });
        var echarts4 = echarts.init(document.getElementById("echarts4"));
        this.echartsOptionsFour = {
          xAxis: {
            data: this.echartsNameFour,
            axisLine: {
              lineStyle: { color: "#3E5063" },
            },
            axisTick: { length: 0 },
            axisLabel: { color: "#71C8FB", fontSize: "14" },
          },
          yAxis: {
            splitLine: {
              lineStyle: {
                color: "#3E5063",
              },
            },
            axisLine: {
              show: true,
              lineStyle: { color: "#3E5063" },
            },
            axisLabel: { color: "#ECF0FF", fontSize: "14" },
          },
          legend: {
            show: true,
            right: 0,
            textStyle: {
              color: "#ECF0FF",
              fontSize: "12",
            },
          },
          grid: { bottom: "15%", right: "0%", left: "15%" },
          series: [
            {
              data: this.echartsNumFive,
              name: "气数值",
              type: "bar",
              stack: "x",
              barWidth: "20%",
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#27E6AB",
                  },
                  {
                    offset: 1,
                    color: "#0B2375",
                  },
                ]),
              },
            },
            {
              data: this.echartsNumSix,
              name: "电数值",
              type: "bar",
              stack: "x",
              barWidth: "20%",
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#FDC81E",
                  },
                  {
                    offset: 1,
                    color: "#0B2375",
                  },
                ]),
              },
            },
          ],
          tooltip: { show: true },
        };
        echarts4.setOption(this.echartsOptionsFour);
        echarts4
          .off("legendselectchanged")
          .on("legendselectchanged", function (params) {
            if (params.name == "气数值") {
              if (!params.selected["气数值"]) {
                if (!params.selected["电数值"]) {
                  echarts4.setOption({
                    legend: { selected: { 电数值: true } },
                  });
                }
              }
            } else {
              if (!params.selected["电数值"]) {
                if (!params.selected["气数值"]) {
                  echarts4.setOption({
                    legend: { selected: { 气数值: true } },
                  });
                }
              }
            }
          });
      });
    },
    //获取告警信息
    getNoticeList() {
      getWarnInfo().then((res) => {
        var date = new Date();
        var y = date.getFullYear();
        var m = date.getMonth() + 1;
        if (m <= 9) {
          m = "0" + m;
        }
        var d = date.getDate();
        if (d <= 9) {
          d = "0" + d;
        }
        var h = date.getHours();
        if (h <= 9) {
          h = "0" + h;
        }
        var ms = date.getMinutes();
        if (ms <= 9) {
          ms = "0" + ms;
        }
        var s = date.getSeconds();
        if (s <= 9) {
          s = "0" + s;
        }
        var listDate = y + "-" + m + "-" + d + " " + h + ":" + ms + ":" + s;
        for (let i = 0; i < res.data.length; i++) {
          this.tempNoticeList.push({
            remark: res.data[i].remark,
            date: listDate,
          });
        }
        this.noticeList = this.tempNoticeList;
      });
    },
    //获取产能进度
    getEchartsThree() {
      getSpeedList().then((res) => {
        let data = res.data;
        for (let i = 0; i < data.length; i++) {
          this.echartsName.push(data[i].device);
          this.echartsData.push(data[i].speed);
        }
        var echarts5 = echarts.init(document.getElementById("echarts5"));
        this.echartsOptions = {
          xAxis: {
            splitLine: { show: false },
            axisLabel: { show: false },
          },
          yAxis: {
            data: this.echartsName,
            axisLine: { show: false },
            axisTick: { length: 0 },
            axisLabel: {
              color: "#fff",
              fontSize: "14",
            },
            inverse: true,
          },
          grid: { left: "15%", top: "10%", bottom: "10%", right: "12%" },
          series: [
            {
              type: "bar",
              data: this.echartsData,
              barWidth: "29",
              label: {
                show: true,
                position: "right",
                valueAnimation: true,
                formatter: function (params) {
                  return params.value + "%";
                },
                color: "#13FCFD",
                fontSize: "14",
              },
              itemStyle: {
                color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                  {
                    offset: 0,
                    color: "#116EE9",
                  },
                  {
                    offset: 1,
                    color: "rgba(60,170,242,0)",
                  },
                ]),
              },
            },
          ],
          dataZoom: [
            {
              type: "slider",
              realtime: true, // 拖动时，是否实时更新系列的视图
              startValue: 0,
              endValue: 2,
              width: 8,
              right: 0,
              // orient: 'vertical', // 设置横向还是纵向, 但是官方不太建议如此使用，建议使用 yAxisIndex 具体指明
              yAxisIndex: [0, 1], // 控制y轴滚动对象
              fillerColor: "#0093ff", // 滚动条颜色
              borderColor: "transparent",
              backgroundColor: "rgba(60,170,242,0.2)", //两边未选中的滑动条区域的颜色
              handleSize: 0, // 两边手柄尺寸
              showDataShadow: false, //是否显示数据阴影 默认auto
              showDetail: false, // 拖拽时是否展示滚动条两侧的文字
              zoomLock: true,
              moveHandleStyle: {
                opacity: 0,
              },
            },
            {
              type: "inside",
              // width: 0,
              startValue: 0,
              endValue: 10,
              minValueSpan: 10,
              yAxisIndex: [0],
              zoomOnMouseWheel: false, // 关闭滚轮缩放
              moveOnMouseWheel: true, // 开启滚轮平移
              moveOnMouseMove: true, // 鼠标移动能触发数据窗口平移
            },
          ],
        };
        echarts5.setOption(this.echartsOptions);
      });
    },
  },
};
</script>
<style lang="less">
* {
  padding: 0;
  margin: 0;
}
html {
  overflow: hidden;
}
.page-all {
  width: 100vw;
  height: 100vh;
  position: relative;
  background-image: url(../assets/img/bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  .top-img {
    width: 100%;
    position: absolute;
    top: -22px;
  }
  .page-inner {
    display: flex;
    padding-top: 52px;
    .page-box {
      background-color: #0612457f;
      .box-title {
        display: flex;
        align-items: center;
        position: relative;
        .title-text {
          position: absolute;
          left: 28px;
          color: #c8dbf4;
          font-size: 16px;
          font-weight: bold;
        }
      }
      .box-title img {
        width: 100%;
      }
    }
    .inner-left {
      width: 27.5%;
      padding-left: 20px;
      .left-top {
        display: flex;
        align-items: center;
        color: #eef6ff;
        font-size: 18px;
        padding-left: 10px;
        .text-blue {
          color: #49a3ff;
        }
      }
      .left-top img {
        width: 22px;
        height: 18px;
        object-fit: contain;
      }
      .left-top div {
        margin: 0 12px;
      }
      .left-mid {
        width: calc(100% - 32px);
        height: calc(25.3vw - 25px);
        position: relative;
        overflow: hidden;
        padding: 25px 16px 0;
        margin-top: 15px;
        .left-mid-btn {
          line-height: 29px;
          display: flex;
          align-items: center;
          position: absolute;
          right: 25px;
          top: 100px;
          z-index: 1;
          border: 1px solid #48ece7;
          font-size: 14px;
          .left-mid-btn-box {
            color: #fff;
            background-color: #000e39;
            padding: 0 16px;
            cursor: pointer;
            user-select: none;
          }
          .left-mid-btn-box:hover,
          .left-mid-btn-active {
            color: #020c2e;
            background-color: #48ece7;
          }
        }
        #echarts1,
        #echarts2 {
          width: 100%;
          height: 85%;
          margin-top: 40px;
        }
      }
      .left-bot {
        padding: 25px 16px 10px;
        margin-top: 29px;
        .left-bot-table {
          font-size: 14px;
          margin-top: 20px;
          .left-bot-table-head,
          .left-bot-table-line:nth-child(2n) {
            background-color: #0f216a;
          }
          .left-bot-table-head,
          .left-bot-table-line {
            display: flex;
            align-items: center;
          }
          .left-bot-table-head {
            color: #48ece7;
          }
          .left-bot-table-line {
            color: #fff;
          }
          .left-bot-head-box,
          .left-bot-table-box {
            width: 40%;
            line-height: 42px;
            text-align: center;
            padding: 0 15px;
          }
          .left-bot-head-box:first-child,
          .left-bot-table-box:first-child {
            width: 20%;
            text-align: left;
          }
          .left-bot-head-box:last-child,
          .left-bot-table-box:last-child {
            width: 15%;
            margin-left: 5%;
          }
        }
      }
    }
    .inner-mid {
      width: 45%;
      padding: 0 38px;
      .mid-top {
        display: flex;
        align-items: baseline;
        padding-top: 63px;
        .mid-top-box {
          width: 25%;
          display: flex;
          align-items: last baseline;
          justify-content: center;
          color: #fff;
          font-size: 19px;
          .mid-top-box-info {
            width: calc(100% - 63px);
            .mid-num {
              width: 90%;
              overflow: hidden;
              font-size: 24px;
              margin-top: 8px;
            }
            .text-lightblue {
              color: #48ece7;
            }
            .text-yellow {
              color: #ffd452;
            }
            .text-blue {
              color: #47a2ff;
            }
            .text-green {
              color: #47ff88;
            }
          }
        }
        .mid-top-box img {
          width: 63px;
          height: 69px;
          object-fit: contain;
          margin-right: 10px;
        }
        .mid-top-box img:first-child {
          height: 66px;
        }
        .mid-top-box img:nth-child(3) {
          width: 62px;
        }
        .mid-top-box img:last-child {
          width: 66px;
        }
      }
      .mid-mid {
        height: 51%;
        background-color: #3d4061;
        margin-top: 42px;
        iframe{
          width: 100%;
          height: 100%;
        }
      }
      .mid-bot {
        display: flex;
        margin-top: 26px;
        .mid-bot-left {
          width: 39%;
          padding: 26px 17px 41px;
          .mid-bot-left-box {
            display: flex;
            .mid-bot-img {
              width: 40%;
              height: calc(15vh - 9px);
              object-fit: cover;
            }
            .mid-bot-left-text {
              width: calc(60% - 16px);
              line-height: 22px;
              text-indent: 24px;
              color: #fff;
              font-size: 12px;
              margin-left: 16px;
            }
          }
        }
        .mid-bot-right {
          width: calc(58.5% - 83px);
          padding: 26px 27px 26px 22px;
          margin-left: 2.5%;
          .mid-bot-right-line {
            display: flex;
            align-items: last baseline;
            font-size: 14px;
            .mid-bot-right-name {
              width: 35%;
              color: #48ece7;
            }
            .mid-bot-right-desc {
              width: 65%;
              text-align: right;
              color: #fff;
            }
          }
          .mid-bot-right-line img {
            width: 18px;
            height: 19px;
            object-fit: contain;
            margin-right: 10px;
          }
        }
        .mid-bot-left .box-title img,
        .mid-bot-right .box-title img {
          width: unset;
          height: 42px;
        }
      }
    }
    .inner-right {
      width: 27.5%;
      padding-right: 20px;
      .right-top {
        line-height: 18px;
        display: flex;
        align-items: center;
        justify-content: right;
        position: relative;
        z-index: 1;
        margin-bottom: 18px;
        .text-blue {
          color: #49a3ff;
          font-size: 18px;
          margin: 0 20px 0 10px;
          cursor: pointer;
          user-select: none;
        }
        .right-btn {
          color: #dbedff;
          font-size: 14px;
          margin-right: 9px;
          cursor: pointer;
          user-select: none;
        }
      }
      .right-top img {
        width: 24px;
        height: 24px;
        object-fit: cover;
        border-radius: 100%;
      }
      .right-bot {
        width: calc(100% - 40px);
        position: relative;
        overflow: hidden;
        padding: 12px 20px 0;
        .echarts3-box {
          width: 100%;
          height: 25.5vh;
          position: relative;
          overflow: hidden;
          margin-top: 30px;
          #echarts3,
          #echarts4 {
            width: 100%;
            height: 100%;
          }
        }
        .right-bot-list-part {
          height: calc(25.9vh - 40px);
          padding: 20px 0;
          .right-bot-list {
            height: 100%;
            .right-bot-list-box {
              line-height: 40px;
              display: flex;
              align-items: center;
              font-size: 14px;
              padding: 0 4px;
              .right-bot-text {
                width: 60%;
                color: #48ece7;
              }
              .right-bot-date {
                width: 40%;
                text-align: right;
                color: #fff;
                padding-right: 5px;
              }
            }
          }
        }
        .echarts5-box {
          width: 100%;
          height: 22.5vh;
          position: relative;
          #echarts5 {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
    }
  }
}
.jtjk {
  height: 28.1vh;
}

.gsjs {
  height: calc(18vh - 63px);
  padding: 22px 0;
}

.cjxx {
  height: calc(18vh - 22px);
  margin-top: 18px;
}

.bzxr {
  height: 265px;
}

.swiper-wrapper {
  transition-timing-function: linear;
  -webkit-transition-timing-function: linear;
  -moz-transition-timing-function: linear;
  -o-transition-timing-function: linear;
}
</style>
